//  Copyright 2018 Google Inc.
//
//  Permission is hereby granted, free of charge, to any person obtaining a copy
//  of this software and associated documentation files (the "Software"), to deal
//  in the Software without restriction, including without limitation the rights
//  to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
//  copies of the Software, and to permit persons to whom the Software is
//  furnished to do so, subject to the following conditions:/
//
//  The above copyright notice and this permission notice shall be included in
//  all copies or substantial portions of the Software./
//
//  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
//  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
//  FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
//  AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
//  LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
//  OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
//  THE SOFTWARE.
//

@import "@material/theme/mixins";
@import "./variables";

// Public mixins

@mixin mdc-select-icon-color($color) {
  &:not(.mdc-select--disabled) {
    @include mdc-select-icon-color_($color);
  }
}

// Private mixins

@mixin mdc-select-icon_ {
  @include mdc-select-icon-color(on-surface);

  .mdc-select__icon {
    display: inline-block;
    position: absolute;
    bottom: 16px;
    box-sizing: border-box;
    width: $mdc-select-icon-size;
    height: $mdc-select-icon-size;
    border: none;
    background-color: transparent;
    fill: currentColor;
    opacity: $mdc-select-icon-opacity;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
  }
}

@mixin mdc-select-icon-color_($color) {
  .mdc-select__icon {
    @include mdc-theme-prop(color, $color);
  }
}

@mixin mdc-select-icon-horizontal-position_($iconPosition, $inputPadding) {
  .mdc-select__icon {
    @include mdc-rtl-reflexive-position(left, $iconPosition);
  }

  // Move the input's position, to allow room for the icon
  .mdc-select__native-control,
  .mdc-select__selected-text {
    @include mdc-rtl-reflexive-property(padding, $inputPadding /* left */, $mdc-select-icon-right-padding /* right */);
  }
}
